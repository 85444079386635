import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Popover, Transition } from '@headlessui/react';
import {
  MenuIcon,
  XIcon,
} from '@heroicons/react/outline';

const Header = ({ location }) => (
  <header className="header bg-shark-500">
    <div className="relative bg-shark-500">
      <div className="mx-auto px-4 sm:px-6 lg:px-12">
        <div className="flex justify-between items-center md:space-x-10">
          <div className="flex lg:justify-start md:w-1/2 lg:w-1/3">
            <Link className="block h-16 lg:h-20 flex items-center" to="/">
              <span className="sr-only">Home</span>
              <StaticImage
                src="../images/logo-200.png"
                placeholder="none"
                width={200}
                height={39}
                alt=""
                role="presentation"
                objectFit="fill"
              />
            </Link>
          </div>
          <Popover className="flex justify-end">
            {({ open }) => (
              <div>
                <div className="lg:hidden">
                  <Popover.Button className="bg-transparent p-2 text-gray-300">
                    <span className="sr-only">Open menu</span>
                    <MenuIcon className="h-8 w-8" aria-hidden="true" />
                  </Popover.Button>
                </div>
                <nav className="hidden lg:flex lg:flex-1 lg:justify-end lg:w-full">
                  {/* eslint-disable max-len */}
                  <Link to="/" className={`flex items-center no-underline h-16 lg:h-20 px-6 tracking-wide ${location.pathname === '/' ? 'text-white font-bold border-b-3 border-sweet-corn-500' : 'text-gray-300 hover:text-white'}`}>
                    <span>Home</span>
                  </Link>
                  {/* eslint-disable max-len */}
                  <Link to="/mission/" className={`flex items-center no-underline h-16 lg:h-20 px-6 tracking-wide ${location.pathname === '/mission/' ? 'text-white font-bold border-b-3 border-sweet-corn-500' : 'text-gray-300 hover:text-white'}`}>
                    <span>Mission</span>
                  </Link>
                  {/* eslint-disable max-len */}
                  <Link to="/ram-shriram/" className={`flex items-center no-underline h-16 lg:h-20 px-6 tracking-wide ${location.pathname === '/ram-shriram/' ? 'text-white font-bold border-b-3 border-sweet-corn-500' : 'text-gray-300 hover:text-white'}`}>
                    <span>Meet Ram Shriram</span>
                  </Link>
                  {/* eslint-disable max-len */}
                  <Link to="/investments/" className={`flex items-center no-underline h-16 lg:h-20 px-6 tracking-wide ${location.pathname === '/investments/' ? 'text-white font-bold border-b-3 border-sweet-corn-500' : 'text-gray-300 hover:text-white'}`}>
                    <span>Investments</span>
                  </Link>
                </nav>

                <Transition
                  show={open}
                  as={Fragment}
                  enter="transition duration-500 ease"
                  enterFrom="transform translate-x-full"
                  enterTo="transform translate-x-0"
                  leave="transition duration-500 ease"
                  leaveFrom="transform translate-x-0"
                  leaveTo="transform translate-x-full"
                  className="fixed top-0 right-0 z-20 h-full sm:h-auto w-10/12 sm:w-1/3 md:w-2/6 lg:hidden"
                >
                  <Popover.Panel
                    focus
                  >
                      <div className="shadow-xl h-full sm:h-auto ring-1 ring-black ring-opacity-5 bg-shark-600">
                        <div className="flex justify-end items-center px-4 sm:px-6">
                          <Popover.Button autoFocus className="h-16 lg:h-20 bg-transparent p-2 text-gray-300">
                            <span className="sr-only">Close menu</span>
                            <XIcon className="h-8 w-8" aria-hidden="true" />
                          </Popover.Button>
                        </div>
                        <div className="pb-8 px-5 space-y-6">
                          <div className="grid grid-cols-1 gap-y-4 gap-x-8">
                            <Popover.Button
                              as={Link}
                              to="/"
                              className="no-underline text-gray-300 hover:text-white"
                            >
                              Home
                            </Popover.Button>
                            <Popover.Button
                              as={Link}
                              to="/mission/"
                              className="border-t border-shark-400 pt-4 no-underline text-gray-300 hover:text-white"
                            >
                              Mission
                            </Popover.Button>
                            <Popover.Button
                              as={Link}
                              to="/ram-shriram/"
                              className="border-t border-shark-400 pt-4 no-underline text-gray-300 hover:text-white"
                            >
                              Meet Ram Shriram
                            </Popover.Button>
                            <Popover.Button
                              as={Link}
                              to="/investments/"
                              className="border-t border-shark-400 pt-4 no-underline text-gray-300 hover:text-white"
                            >
                              Investments
                            </Popover.Button>
                          </div>
                        </div>
                      </div>
                  </Popover.Panel>
                </Transition>
              </div>
            )}
          </Popover>

        </div>
      </div>
    </div>
  </header>
);

Header.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

Header.defaultProps = {};

export default Header;
