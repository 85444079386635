import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';

const Footer = () => (
  <StaticQuery
    query={graphql`
      query {
        block__footer_legal: markdownRemark(
          frontmatter: {
            machine_name: {
              eq: "footer-legal"
            }
          }
        ) {
          html
        }
        block__footer_message: markdownRemark(
          frontmatter: {
            machine_name: {
              eq: "footer-message"
            }
          }
        ) {
          html
        }
      }
    `}
    render={data => (
      <footer id="footer" className="bg-shark-500">
        <div className="mx-auto py-12 px-4 sm:px-6 lg:px-8 max-w-lg lg:max-w-7xl">
          <div className="grid lg:grid-cols-2 gap-8 lg:gap-24 xl:gap-36">

            {/* Content */}
            <div className="col-span-2 lg:col-span-1">
              {data.block__footer_message.html
                && (
                  <section>
                    <div
                      className="text-sm md:text-base"
                      dangerouslySetInnerHTML={{ __html: data.block__footer_message.html }}
                    />
                  </section>
                )
              }
            </div>

            <div className="col-span-2 lg:col-span-1">
              <header className="mt-12 lg:mt-0">
                <h3 className="text-xl text-white font-bold">Contact Us</h3>
              </header>
              <div>
                <ul className="mb-6">
                  <li>
                    <FontAwesomeIcon className="text-white mr-2 w-6 h-6 inline align-bottom" icon={faEnvelope} />
                    <a href="mailto:info@sherpalo.com" className="inline-block">info@sherpalo.com</a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-span-2">
              {data.block__footer_legal.html
                && (
                  <div
                    className="my-6 text-sm lg:text-base lg:text-center text-gray-400"
                    dangerouslySetInnerHTML={{ __html: data.block__footer_legal.html }}
                  />
                )
              }
            </div>

          </div>
        </div>
      </footer>
    )}
  />
);

export default Footer;
