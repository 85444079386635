import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

import Header from './header';
import Footer from './footer';

// Assets
import '../assets/main.css';

// Animate On Scroll (AOS) library
import AOS from 'aos';
import 'aos/dist/aos.css';

const Layout = ({ children, location }) => {
  const [loading, setLoading] = useState('is-loading');

  useEffect(() => {
    AOS.init();

    setTimeout(() => {
      setLoading('');
    }, 100);
  }, []);

  useEffect(() => {
    AOS.refresh();
  });

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <Header location={location} />
          <div
            className={`body ${loading}`}
          >
            <main>
              {children}
            </main>
            <Footer />
          </div>
        </>
      )}
    />
  );
}

Layout.propTypes = {};

export default Layout;
